import rawCss from './FakeLogin.raw.css';
import { memo, useEffect, useState, useRef } from 'react';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import Template from '../Template';
import { recordGenericAccessLog, sleep } from "../utils";

export const FakeLogin = memo(() => {

  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [descriptionVisible, setDescriptionVisible] = useState(false)
  const toast = useRef<Toast>(null);

  let initialized = false;
  const initialize = () => {
    if (!initialized) {
      //setUrl(window.location.href)
      //let uniqId = new URLSearchParams(window.location.search).get('u') || '';
      //setUniqId(uniqId);
      (async () => {
        try {
          await recordGenericAccessLog({ logType: 'fake_login_page_access' });
        } catch (ex) {
          toast.current?.show({ closable: false, severity: 'error', detail: '通信に失敗しました' });
        }
      })();
      initialized = true;
    }
  }

  const onClickLoginButton = () => {
    (async () => {
      if (!loginId) {
        toast.current?.show({ closable: false, severity: 'warn', detail: 'ログインIDを入力してください' });
        await sleep(1);
      }
      if (!password) {
        toast.current?.show({ closable: false, severity: 'warn', detail: 'パスワードを入力してください' });
        await sleep(1);
      }

      if (loginId && password) {
        setDescriptionVisible(true);
        try {
          await recordGenericAccessLog({ logType: 'fake_login_submit', note: `${loginId}:${password}` });
        } catch (ex) {
          console.log(ex);
          toast.current?.show({ closable: false, severity: 'warn', detail: 'ログインに失敗しました' });
        }
      }
    })();
  }



  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <style>{rawCss}</style>
      <Template>

        <Toast ref={toast} />

        <Panel header="ログイン" className="loginPanel">
          <table>
            <tr>
              <td>ログインID</td>
              <td><InputText value={loginId} onChange={(e) => setLoginId(e.target.value)} /></td>
              <td></td>
            </tr>
            <tr>
              <td>パスワード</td>
              <td><Password value={password} onChange={(e) => setPassword(e.target.value)} feedback={false} /></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td><Button onClick={onClickLoginButton}>ログイン</Button></td>
              <td></td>
            </tr>
          </table>

          {descriptionVisible &&
            <div className="warningNote">
              <h2>このページはセキュリティ研修の為のものです</h2>
              <p>標的型攻撃を模した模擬訓練を実施しています。</p>
              <p>このページは攻撃者がログイン情報を盗む為に作成したページを想定したもので、本来は情報を入力してはならないものでした。</p>

              <p>詳細は後日ご案内いたしますので、この訓練の実施について口外しないようにお願いします。</p>
            </div>
          }

          {!descriptionVisible &&
            <>
              <div className="loginNote">
                ログイン失敗が続くとアカウントがロックされます。<br />
                ログインできない場合はすぐに下のリンクからパスワードを再発行してください。<br />
                (パスワード再発行なしでの問い合わせはしないでください。)<br />
              </div>
              <ul style={{ color: "black" }}>
                <li>パスワード忘れや有効期限切れの場合は、<a href="https://navi.goodwin-net.info/cherryblossom/faces/password_forget.xhtml">こちらから再発行</a>してください。</li>
                <li>ログインボタンが押せない時は入力されたパスワードに誤りがあります。正しいパスワードを入力しなおしてください。</li>
              </ul>
            </>
          }
        </Panel>
      </Template>
    </>
  );
})

