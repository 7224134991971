import rawCss from './WebMailFakeLogin.raw.css';
import { memo, useEffect, useState, useRef } from 'react';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { invokeUniqIdToEmail, recordGenericAccessLog, sleep } from "../utils";

export const WebMailFakeLogin = memo(() => {

  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [descriptionVisible, setDescriptionVisible] = useState(false)
  const toast = useRef<Toast>(null);

  let initialized = false;

  const uniqIdToEmail = async (uniqId: string) => {
    return await invokeUniqIdToEmail({ uniqId });
  };

  const initialize = async () => {
    if (!initialized) {

      let uniqId = new URLSearchParams(window.location.search).get('u') || '';
      let email: string = await uniqIdToEmail(uniqId);
      setLoginId(email);

      //setUrl(window.location.href)
      //let uniqId = new URLSearchParams(window.location.search).get('u') || '';
      //setUniqId(uniqId);
      (async () => {
        try {
          await recordGenericAccessLog({ logType: 'fake_login_page_access' });
        } catch (ex) {
          toast.current?.show({ closable: false, severity: 'error', detail: '通信に失敗しました' });
        }
      })();
      initialized = true;
    }
  }

  const onClickLoginButton = () => {
    (async () => {
      if (!loginId) {
        toast.current?.show({ closable: false, severity: 'warn', detail: 'メールアドレスを入力してください' });
        await sleep(1);
      }
      if (!password) {
        toast.current?.show({ closable: false, severity: 'warn', detail: 'パスワードを入力してください' });
        await sleep(1);
      }

      if (loginId && password) {
        setDescriptionVisible(true);
        try {
          await recordGenericAccessLog({ logType: 'fake_login_submit', note: `${loginId}:${password}` });
        } catch (ex) {
          console.log(ex);
          toast.current?.show({ closable: false, severity: 'warn', detail: 'ログインに失敗しました' });
        }
      }
    })();
  }



  useEffect(() => {
    (async () => {
      await initialize();
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <style>{rawCss}</style>


      <Toast ref={toast} />

      <Panel header="Welcome to Webmail" className="loginPanel">
        <table>
          <tr>
            <td><strong>Username</strong></td>
            <td><InputText style={{ width: '30em', fontWeight: 'bold' }} value={loginId} onChange={(e) => setLoginId(e.target.value)} /></td>
          </tr>
          <tr>
            <td><strong>Password</strong></td>
            <td>
              <Password
                className="bold-password-input"
                style={{ width: '30em' }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                feedback={false} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <label><input type="checkbox" id="remember_email" />メールアドレスを保存する</label></td>
          </tr>
        </table>


        <div className="loginNote">
          ※メールアドレスとパスワードを入力して、ログインしてください。<br />
        </div>

        <Button onClick={onClickLoginButton}>Login</Button>




        {descriptionVisible &&
          <div className="warningNote">
            <h2>このページはセキュリティ研修の為のものです</h2>
            <p>標的型攻撃を模した模擬訓練を実施しています。</p>
            <p>このページは攻撃者がログイン情報を盗む為に作成したページを想定したもので、本来は情報を入力してはならないものでした。</p>

            <p>詳細は後日ご案内いたしますので、この訓練の実施について口外しないようにお願いします。</p>
          </div>
        }




      </Panel>

    </>
  );
})

