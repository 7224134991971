import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Home } from './pages/Home';
import { FakeLogin } from './pages/FakeLogin';
import { MalwareDistribute } from './pages/MalwareDistribute'
import { WebMailFakeLogin } from './pages/WebMailFakeLogin';
import { useEffect } from "react";

export const App = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME}>
      <AppRoutes />
    </BrowserRouter>
  );
};

export const AppRoutes = () => {
  const location = useLocation();
  useEffect(
    () => { document.title = location.pathname },
    [location],
  )

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="login" element={<FakeLogin />} />
      <Route path="distribute" element={<MalwareDistribute />} />
      <Route path="webmail" element={<WebMailFakeLogin />} />
    </Routes>
  )
};