import { Card } from "primereact/card";
import Template from "../Template";


export const Home = () => {
    return (
        <Template>
            <Card title="At last...">
            <p>You got me!</p>
            </Card>
        </Template>
    )
};
