import rawCss from './MalwareDistribute.raw.css';
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { recordGenericAccessLog, sleep } from "../utils";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";


export const MalwareDistribute = () => {
    const toast = useRef<Toast>(null);

    let initialized = false;
    const initialize = async () => {
        if (!initialized) {
            //document.getElementsByTagName("html")[0].classList.add(styles.malwareDistributeHtml);
            try {
                await recordGenericAccessLog({ logType: 'malware_distribute_page_access' });
            } catch (ex) {
            };
        }
    };

    const onDownloadClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.currentTarget.href = "https://secure.eicar.org/eicar.com";

        (async () => {
            try {
                await recordGenericAccessLog({ logType: 'malware_distribute_download_click' });
            } catch (ex) {
            };
            toast.current?.show({
                closable: false,
                life: 20000,
                severity: 'error',
                detail: 'このサイトはセキュリティ研修用の偽サイトです。\nリンクのURLは表示されている場所に遷移するものとは限りません。\n悪意のあるリンクはクリック時に内容が変化したり、不正なプログラムを動作させたりします。',
                contentStyle: { whiteSpace: 'pre-wrap' }
            })

            await sleep(3000);
            window.open('https://secure.eicar.org/eicar.com', "_blank");

        })();

    };

    useEffect(() => {
        initialize();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <style>{rawCss}</style>
            <Toast ref={toast} />
            <Card>

                <ReactMarkdown >{`
# データ流出確認サイト


マルウェアの感染により、データ流出が発生したかどうかの診断を行います。
下記のURLより、診断ツールをダウンロードして実行してください。

## データ流出診断ツールVer3.2 のダウンロード

診断結果で、「データ流出の可能性があります」と表示された場合は、
画面を消さずに、システム担当者へご連絡ください。


## データ流出診断ツール

次のリンクからダウンロードして実行してください。

`}
                </ReactMarkdown>

                <p style={{ padding: '2rem' }}><a href="https://central.github.com/deployments/desktop/desktop/latest/win32" onClick={onDownloadClick}>データ流出診断ツール(Ver3.2)をダウンロード</a></p>

                <ReactMarkdown >{`





## 使用方法
・上記URL からツールをダウンロード
・データ流出が疑われるホストでツールを実行
・出力されるレポートを確認

#### コマンドオプション
(v3.2 追加)

レポート出力先ディレクトリ指定 (デフォルト: カレントディレクトリ)
/output [出力先ディレクトリ] または -output [出力先ディレクトリ]

コマンドライン出力抑止
/quiet または -quiet

JSON形式でのレポート出力
/json または -json

詳細表示（レポート出力なし）
/debug または -debug

ヘルプ表示
/help または -help


## データ流出の検知方法
ホスト上のプロセス一覧およびログの記録から、マルウェアによる
データ流出のふるまいを検知します。

© Y2KF Ltd.
`}
                </ReactMarkdown>

            </Card>
        </div>
    )
};
