import { ReactNode } from "react";
import "./Template.css";

type Props = {
    children: ReactNode
}

function Template(props: Props) {
    const { children } = props;
    return (
        <>
            <div id="top">

                <h2 className="top_header">
                    <div className="top_header_banner">
                        <img src="https://navi.goodwin-net.info/cherryblossom/faces/jakarta.faces.resource/goodwin_navi_logo.png?ln=img" className="goodwinLogoImage" alt="" />
                    </div>
                </h2><span id="messages"></span>
            </div>

            {children}

            <div id="bottom">

                <p className="copyright">© Goodwin Co. Ltd.</p>
            </div>
        </>
    );
}

export default Template;