import axios from 'axios';

interface logBody {
  url: string,
  uniq_id: string,
  log_type: string,
  note?: string,
};

export const recordGenericAccessLog = async (params: { logType: string, note?: string }) => {

  let url = window.location.href
  let uniqId = new URLSearchParams(window.location.search).get('u') || '';

  let body: logBody = {
    "url": url,
    "uniq_id": uniqId,
    "log_type": params.logType,
  };

  if (params.note) {
    body["note"] = params.note;
  }

  let res = await axios.post(process.env.REACT_APP_API_URL + '/api/log', body)
  console.log(res.data);

};

interface InvokeUniqIdToEmailBody {
  uniq_id: string,
};

export const invokeUniqIdToEmail = async (params: { uniqId: string }) => {

  let body: InvokeUniqIdToEmailBody = {
    "uniq_id": params.uniqId,
  };

  let res = await axios.post(process.env.REACT_APP_API_URL + '/api/uid_to_email', body)
  console.log(res.data);
  return res.data.email;

};

export const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));